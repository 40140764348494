

const news =[
    {
        id: "1",
        img: "./assets/news/n8.jpg",
        heading: "Tissue and Hygiene",
        paragraph: "Our tissue and hygiene market, technology and business know-how provides a unique combination of insights for all stakeholders of the both tissue and hygiene sectors value chains.",
        imgsrc:"../assets/news/tissue.jpg",
        para1:"Tissue and hygiene market features a number of market and technology trends such as requirement for sustainability, increasing quality, evolving roles of brands/private labels, plastic-free packaging, circularity, e-commerce, changes in pulp and recovered fibre markets to mention but a few. A fragmented industry structure still maintains M&A activity.",
        para2:"Our tissue sector expertise covers business strategies adapted for a variety of technologies and deep understanding of tissue and hygiene product markets throughout the value chain. Our passion is to help our clients in the tissue and hygiene sectors finding the best possible ways to success.",
        para3:"We advise all participants of tissue and hygiene value chains; tissue paper and nonwoven producers, converters of tissue, manufacturers of personal care and hygiene products, technology suppliers, investors, financiers, institutions and other interest groups. We help our clients to achieve their business objectives in the fascinating world of tissue and hygiene.",
        para4:" We have a proven track record in: Corporate and business strategies, Expansion and entry strategies, Feasibility studies and investment advisory, Transactions and Due diligence services, Market and competitive environment analysis, Raw material availability, price development and trends, Technology evaluations",
        Link: '/News1',


    },
    {
        id: "2",
        img: "./assets/news/n7.jpg",
        heading: "Pulp and Biorefining",
        paragraph: "Our management consultants are the foremost authority on pulp, providing advice to stakeholders of industries with over 400 Mt/a of various fibre, from paper-making fibre to wood/non-wood fibres that are used in the hygiene, textile, acetate and ether industries.",
        imgsrc:"../assets/news/biorefining.jpg",
        para1:"The pulp industry has maintained a strong tailwind for a few years now, for many reasons. Steady growth of the global tissue paper market, exacerbated by tightening supply of recovered paper, and dramatic changes in paper-making fibre furnish have contributed to the present state of the pulp market. In addition, products based on renewable raw materials are now increasingly perceived as a sustainable solution for the substitution of both natural fibre and fossil-based materials in the textile industries.",
        para2:"The future of pulp industries also comes with a number of challenges. Business participants are adjusting to new equilibrium in terms of rapidly changing end-use patterns, virgin fibre supply sources and environmental compliance",
        para3:"We are the advisors of choice for all pulp business participants, including pulp and paper companies, diverse suppliers and solution providers, investors, financiers, institutions, pulp traders, logistics companies and other interest groups. We help our clients to navigate through the rapidly changing pulp business environment.",
        para4:"We are acknowledged experts in: Corporate, marketing and fibre strategies, Due diligence services, Feasibility studies and investment advisory, Expert witness services, Market and competitive environment analysis including forecasting, Raw material supply and plantation development",
        Link: '/News2',

    },
    {
        id: "3",
        img: "../assets/news/n6.jpg",
        
        heading: "Paper Industry",
        paragraph: "Digitalisation and sustainability are the key change drivers of the paper industry",
        imgsrc:"../assets/news/paper.jpg",
        para1:"AFRY offers in-depth strategic advisory services and proven engineering and project implementation capabilities for global paper industry clients in all phases of their business lifecycle. Our track record in the paper industry and related advisory and engineering services spans more than six decades.",
        para2:"The world's paper industry is facing significant structural changes as the decline of graphic paper consumption continues. At the same time, specialty paper grades are becoming increasingly attractive to paper manufacturers. Labels, papers with different functional properties or printability, are in growing demand. There is continuous innovation ongoing with paper, leading to new functional end uses and new possibilities for the papermakers.",
        para3:"The recyclability of paper is one of its main strengths and unique features. With many sustainable properties, paper is taking its place as one of the best alternatives for replacing plastic packaging and wrapping and helping to reduce the CO2 footprint. Paper can be recycled around 5-7 times and approximately 57% or 238 million tonnes of all fibre used for paper production nowadays comes from recovered paper.",
        para4:"    Paper mills are under continuous pressure to improve material and energy efficiency and optimizing operating costs. By assessing operations, AFRY can help clients to identify and implement the most feasible and reasonable improvements for reaching higher operational efficiency. AFRY delivers deep advisory insight on the changing global paper markets. With the rapidly changing world, a need for rethinking and repositioning is on the agenda for many paper companies. The consumption of containerboards and carton boards is steadily increasing and grade conversion rebuilds to produce more profitable products can be an alternative. AFRY has a strong track record of state-of-the-art greenfield projects as well as conversion rebuilds. We work closely with our clients and assist them throughout the whole process, from evaluating project feasibility to project implementation.",
        Link: '/News3',

    },
    {
        id: "4",
        img: "./assets/news/n4.jpg",
        heading: "Paper",
        paragraph: "We are the leading advisor for the global paper industry and all its stakeholders, working across all grades from deep commodities such as newsprint, through to high-end specialties such as banknotes and tea-bags. Papersuppliers sector focused expertise is uniquely positioned supported by both engineering and investment banking teams.",
        imgsrc:"../assets/news/paper1.jpg",
        para1:"Paper products are all around us and an intrinsic part of day-to-day life. Printed materials have been essential for human development for hundreds of years, and over the last century many other applications have developed which are now considered to be essential.",
        para2:"The paper sector itself is a capital intensive and long term global industry. It has continued to develop, driven both by technology and external forces. Recent challenges have come from electronic media, exacerbated by a shift in advertising away from printed publications. At the same time there has been growth in speciality papers - labels, filtration, thermal paper, greaseproof, security paper, to name a few, as well as in packaging and tissue.",
        para3:"Other key developments include: recycling where paper is a key resource in the circular economy, and M&A which is ripe with opportunities seized by both financial sponsors and industrials. The paper sector continues to develop and refocus.",
        para4:" ",
        Link: '/News4',

    },
    {
        id: "5",
        img: "./assets/news/n3.jpg",
        heading: "Packaging",
        paragraph: "We are the leading advisor for the global packaging industry and its stakeholders. Our services cover all packaging materials and solutions, with deep expertise in containerboard/corrugated, cartonboards and various packaging papers.",imgsrc:"../assets/news/packaging.webp",
        para1:"Global fibre-based packaging market has been growing steadily for a few years now, and is currently the largest and most attractive growth market within the paper and paperboard industry. The packaging segment is in change, and the increasing requirements for sustainable packaging and resource efficiency create new business opportunities for the leading edge companies.",
        para2:"The future of packaging industries also comes with a number of challenges. To be able to win in the battle against other packaging substrates, the fibre-based packaging industry has to adapt to the changing environment and ensure that new emerging opportunities deriving e.g. from plastic substitution, e-commerce growth etc. are turned into profitable businesses.",
        para3:"We are the preferred advisor for all packaging business participants including paper and paperboard companies, various suppliers and solution providers, investors, financiers, institutions and other interest groups. We help our clients to explore the changing packaging business environment to find the concrete actions and business opportunities.",
        para4:" ",
        Link: '/News5',

    },
    {
        id: "6",
        img: "./assets/news/n5.jpg",
        heading: "Recovered Paper",
        paragraph: "We are the leading advisor to the paper recycling industry and all its stakeholders. Papersuppliers sector focused expertise is uniquely positioned supported by both engineering and investment banking teams.",
        imgsrc:"../assets/news/recycle.jpg",
        para1:"AFRY offers in-depth strategic advisory services and proven engineering and project implementation capabilities for global paper industry clients in all phases of their business lifecycle. Our track record in the paper industry and related advisory and engineering services spans more than six decades.",
        para2:"The world's paper industry is facing significant structural changes as the decline of graphic paper consumption continues. At the same time, specialty paper grades are becoming increasingly attractive to paper manufacturers. Labels, papers with different functional properties or printability, are in growing demand. There is continuous innovation ongoing with paper, leading to new functional end uses and new possibilities for the papermakers.",
        para3:"The recyclability of paper is one of its main strengths and unique features. With many sustainable properties, paper is taking its place as one of the best alternatives for replacing plastic packaging and wrapping and helping to reduce the CO2 footprint. Paper can be recycled around 5-7 times and approximately 57% or 238 million tonnes of all fibre used for paper production nowadays comes from recovered paper.",
        para4:"    Paper mills are under continuous pressure to improve material and energy efficiency and optimizing operating costs. By assessing operations, AFRY can help clients to identify and implement the most feasible and reasonable improvements for reaching higher operational efficiency. AFRY delivers deep advisory insight on the changing global paper markets. With the rapidly changing world, a need for rethinking and repositioning is on the agenda for many paper companies. The consumption of containerboards and carton boards is steadily increasing and grade conversion rebuilds to produce more profitable products can be an alternative. AFRY has a strong track record of state-of-the-art greenfield projects as well as conversion rebuilds. We work closely with our clients and assist them throughout the whole process, from evaluating project feasibility to project implementation.",
        Link: '/News6',

    },
    {
        id: "7",
        img: "./assets/news/n9.jpg",
        heading: "Pulp & Paper",
        paragraph: "A strategic partner ensuring the highest levels of technology, safety and quality",
        imgsrc:"../assets/news/pulp.jpg",
        para1:"Pulp and Paper Plants are highly intensive capital assets. Market projections must be taken into consideration in a scenario where consumer markets and fiber sourcing are many times located in different geographical areas. Those scenarios are impacted, among others, by demographical, social, and environmental trends and legislation.",
        para2:"The source of pulp is mostly wood, a sustainable renewable resource. Pulp offers sustainable and recyclable choices for replacing various fossil-based materials when further refined into higher-value paper and packaging materials, and various bio-based novel products.",
        para3:"Our deep strategic insight and top-level process technology understanding combined with our local presence will respond to the tightening requirements set by the stakeholders. We can help you to plan, develop and implement strategically located state-of-the-art production facilities in accordance with the best available technology and updated market trends. Our mutating concept is gradually transforming the classic pulp mill into a biorefinery plant with the best environmental performance.",
        para4:"AFRY's offering for Bio-based industry clients includes advisory, engineering, and project implementation services for identifying, evaluating, and implementing renewable biomass-based investment options and projects. AFRY uniquely understands the entire biomass value chain – from raw materials to end-product markets. The projects AFRY designs and implements are safe, energy, and resource-efficient and meet stringent environmental standards.",
        Link: '/News7',

    },
    {
        id: "8",
        img: "./assets/news/n1.jpg",
        heading: "Pulp Industry",
        paragraph: "The pulp mills of today are developing into integrated biorefineries, where utilisation of all process side-streams is considered from the early design phases.",
        imgsrc:"../assets/news/pulpindustry.jpg",
        para1:"AFRY offers strong business, technology, and process know-how for clients in the pulp industry. Our market insight and strategic advisory capabilities on global pulp-related business are unique. We have a track record in this industry that spans over 6 decades. We are proud to have been involved in the implementation of 90% of the world's largest pulp mills, and in countless rebuilds and expansion projects, many of these for long-term repeat clients.",
        para2:"The demand of pulp continues to increase driven by demographic changes and overall economic growth. The rapid evolution of e-commerce and increasing focus on sustainability are contributing positively to the need of fiber-based packaging materials. Although there is a continuing decline in many of the traditional paper grades, the growth in packaging and hygiene products, as well as the strongly growing pulp demand in China, will balance out the deficit and keep up the demand.",
        para3:"This traditional business is reshaping and transforming into new areas, where new partnerships and opportunities take form. Pulp is an intermediate product that is further processed into higher-value consumables. The traditional ones are products like paper, packaging, tissue, and also textiles.",
        para4:"With advancing technologies, many new value-added end uses are emerging from the pulping process or its side streams. These products will have all the properties we now look for in fossil-based materials but without the carbon footprint. This “pulp revolution” will help us to reduce our CO2 emissions and the dependency on fossil materials. We will see an exponential growth of new bioproducts in areas such as construction, transportation, energy, a multitude of consumer products and in food and pharma. The convergence of industries brings in new players to the biorefining industry, creating new partnerships and opportunities along the value chains.",
        Link: '/News7',

    },

]

export default news;