import React from 'react'
import Layout from './../Components/Layout';


const Gallery = () => {
  return (
    <Layout>
        <div className='container mt-5'>
    <div className='row'>
      <div className='col-md-12 text-center'>
<h1 style={{fontFamily:"Time New Roman", fontWeight:"900", marginTop:"20px", fontSize:"58px"}}>Gallery</h1>
<hr className='mx-auto mt-2 ' style={{width:"120px", backgroundColor:"#FFC107", height:"1px"}}/>
      </div>
    </div>
  </div>

  {/* img section */}
<div className='container'>
<div className='row my-3'>
<div className='col-md-12 '>
<img alt="gallery"  src="./assets/11.png" style={{width:"100%", height:"300px"}}  />
</div>
</div>
<div className='row my-5'>
<div className='col-md-6 '>
<img alt="gallery"  src="./assets/A4.png" style={{width:"100%", height:"300px"}} />
</div>
<div className='col-md-6 '>
<img alt="gallery"  src="./assets/4.png" style={{width:"100%", height:"300px"}} />
</div>
</div>
</div>



<section className="text-gray-600 body-font">
  <div className="container px-5 pb-24 mx-auto flex flex-wrap">
    <div className="flex flex-wrap md:-m-2 -m-1">
      <div className="flex flex-wrap w-1/2">
        <div className="md:p-2 p-1 w-1/2">
          <img alt="gallery" className="w-full object-cover h-full object-center block" src="./assets/p1.webp" />
        </div>
        <div className="md:p-2 p-1 w-1/2">
          <img alt="gallery" className="w-full object-cover h-full object-center block" src="./assets/6.png" />
        </div>
        <div className="md:p-2 p-1 w-full">
          <img alt="gallery" className="w-full h-full object-cover object-center block" src="./assets/bleached paper.png" />
        </div>
      </div>
      <div className="flex flex-wrap w-1/2">
        <div className="md:p-2 p-1 w-full">
          <img alt="gallery" className="w-full h-full object-cover object-center block" src="./assets/carbonless paper.png" />
        </div>
        <div className="md:p-2 p-1 w-1/2">
          <img alt="gallery" className="w-full object-cover h-full object-center block" src="./assets/p5.webp" />
        </div>
        <div className="md:p-2 p-1 w-1/2">
          <img alt="gallery" className="w-full object-cover h-full object-center block" src="./assets/every.png" />
        </div>
      </div>
    </div>
  </div>
</section>
{/* Gallery */}
<div className='container'>

<div className="row">
  <div className="col-lg-4 col-md-12 mb-4 mb-lg-0">
    <img src="./assets/dull golden.png" className="w-100 shadow-1-strong rounded mb-4" alt="Boat on Calm Water" style={{height:'200px'}} />
    <img src="./assets/transparent paper.png" className="w-100 shadow-1-strong rounded mb-4" alt="Wintry Mountain Landscape" style={{height:'450px'}} />
  </div>
  <div className="col-lg-4 mb-4 mb-lg-0">
    <img src="./assets/butter paper.png" className="w-100 shadow-1-strong rounded mb-4" alt="Mountains in the Clouds" style={{height:'450px'}}/>
    <img src="./assets/3.png" className="w-100 shadow-1-strong rounded mb-4" alt="Boat on Calm Water" style={{height:'200px'}}/>
  </div>
  <div className="col-lg-4 mb-4 mb-lg-0">
    <img src="./assets/offset paper.png" className="w-100 shadow-1-strong rounded mb-4" alt="Waves at Sea" style={{height:'200px'}}/>
    <img src="./assets/silver transparent back.png" className="w-100 shadow-1-strong rounded mb-4" alt="Yosemite National Park"  style={{height:'450px'}}/>
  </div>
</div>
</div> 

    </Layout>
  )
}

export default Gallery
