import React from 'react'
import Layout from '../Components/Layout'
import { Link } from 'react-router-dom'

const Home = () => {
  return (
    <Layout>
      <section className="construction-section ">
      <div className='container' style={{marginTop:"65px"}}>
        <div className='row '>
          <div className='col-md-5 bg-dark text-light'>
            <div className='row' style={{backgroundColor:"#FFC107"}}>
              <div className='col-md-1 '>
                    &nbsp;
              </div>
              <div className='col-md-11 bg-light text-dark'>
              <div className="section-text ">
              <h2 className='text-start'>We are provide best quality paper</h2>
              <p>
              Every product we make performs at the highest level for you, our communities while making your work look great.
              </p>
              <div className="buttons">
                <Link to='/contact'>  <button className="btn contbtn ">CONTACT US</button> </Link>
               <Link to='/product'><button className="btn serbtn">SERVICES</button> </Link>
                
              </div>
            </div>
              </div>
            </div>
          </div>
          <div className='col-md-6'>

          </div>
        </div>
      </div>
    </section>

    
   

    {/* <div className="carousel-item">
  <div className="carousel-caption d-none d-md-block">
    <h5>agf</h5>
    <p>fgfgf</p>
  </div>
</div> */}
   

    {/* about section start here */}
    <div className='container mt-5'>
      <div className='row'>
      <div className='col-md-6'>
          <img className='imgboxx' src='./assets/img2.webp' style={{width:"100%", height:"450px"}} alt='about' />
        </div>
        <div className='col-md-6'>
            <h3 className='mt-3 fw-900' style={{fontFamily:"Times New Roman",           fontSize:"58px"}}>About us</h3>
            <hr className=' mt-1 ' style={{width:"130px",             backgroundColor:"#FFC107", height:"1px"}}/>
            <p>
            <br/>
                Welcome to our Paper suppliers! We are dedicated to providing high-quality paper products that are sustainable and environmentally friendly. Our team is committed to excellence, using the latest technology and innovative processes to deliver products that meet the needs of our customers.
            <br/><br/>

                With a sleek design in black, yellow, and grey, our website reflects our modern approach to the Paper suppliers. The white background symbolizes our commitment to transparency and purity in everything we do.
            <br/><br/>
 
                At our core, we believe in the power of paper to inspire creativity, foster communication, and drive progress. Join us on this journey as we strive to make a positive impact on the world through our passion for paper.
            </p>
        </div>      
      </div>
    </div>
    {/* about section end here */}

    <section class="text-gray-600 body-font texture">
  <div class="container px-5 py-24 mx-auto">
    
    <div class="flex flex-wrap -m-4">
      <div class="xl:w-1/3 md:w-1/2 p-4">
        <div class="p-6 rounded-lg " style={{backgroundColor:"#FFC107",boxShadow: "0px 25px 20px -20px rgba(0, 0, 0, 0.45)"}} id='product'>
        
          <h2 class="text-lg text-gray-900 font-medium title-font mb-2 text-center">Premium Paper</h2>
          <hr className='mx-auto ' style={{width:"50px", backgroundColor:"#000", height:"1px"}}/>
          <p class="leading-relaxed text-base">High-quality paper for important documents and presentations</p>
          
        </div>
      </div>
      <div class="xl:w-1/3 md:w-1/2 p-4">
        <div class="border border-gray-200 p-6 rounded-lg " style={{backgroundColor:"#fff",boxShadow: "0px 25px 20px -20px rgba(0, 0, 0, 0.45)"}} id='product'>
        
          <h2 class="text-lg text-gray-900 font-medium title-font mb-2 text-center">Multipurpose Paper</h2>
          <hr className='mx-auto ' style={{width:"50px", backgroundColor:"#FFC107", height:"1px"}}/>
          <p class="leading-relaxed text-base">Versatile paper suitable for printing, copying, and everyday use.</p>
          
        </div>
      </div>
      <div class="xl:w-1/3 md:w-1/2 p-4">
        <div class="border border-gray-200 p-6 rounded-lg " style={{backgroundColor:"#fff",boxShadow: "0px 25px 20px -20px rgba(0, 0, 0, 0.45)"}} id='product'>
        
          <h2 class="text-lg text-gray-900 font-medium title-font mb-2 text-center">Everyday Printer Paper</h2>
          <hr className='mx-auto' style={{width:"50px", backgroundColor:"#FFC107", height:"1px"}}/>
          <p class="leading-relaxed text-base">Reliable, versatile, and perfect for all your daily printing needs.</p>
          
        </div>
      </div>
      <div class="xl:w-1/3 md:w-1/2 p-4">
        <div class="border border-gray-200 p-6 rounded-lg " style={{backgroundColor:"#fff",boxShadow: "0px 25px 20px -20px rgba(0, 0, 0, 0.45)"}} id='product'>
        
          <h2 class="text-lg text-gray-900 font-medium title-font mb-2 text-center">Colored Paper</h2>
          <hr className='mx-auto' style={{width:"50px", backgroundColor:"#FFC107", height:"1px"}}/>
          <p class="leading-relaxed text-base">Vibrant hues to brighten your projects and unleash your creativity.</p>
          
        </div>
      </div>
      <div class="xl:w-1/3 md:w-1/2 p-4">
        <div class="border border-gray-200 p-6 rounded-lg " style={{backgroundColor:"#fff",boxShadow: "0px 25px 20px -20px rgba(0, 0, 0, 0.45)"}} id='product'>
        
          <h2 class="text-lg text-gray-900 font-medium title-font mb-2 text-center">Cardstock</h2>
          <hr className='mx-auto' style={{width:"50px", backgroundColor:"#FFC107", height:"1px"}}/>
          <p class="leading-relaxed text-base">Thick, sturdy paper for invitations, business cards, and crafts.</p>
          
        </div>
      </div>
      <div class="xl:w-1/3 md:w-1/2 p-4">
        <div class="border border-gray-200 p-6 rounded-lg " style={{backgroundColor:"#fff",boxShadow: "0px 25px 20px -20px rgba(0, 0, 0, 0.45)"}} id='product'>
        
          <h2 class="text-lg text-gray-900 font-medium title-font mb-2 text-center">Texture Paper</h2>
          <hr className='mx-auto' style={{width:"50px", backgroundColor:"#FFC107", height:"1px"}}/>
          <p class="leading-relaxed text-base">Paper with a unique surface texture for artistic projects and special invitations.</p>
          
        </div>
      </div>
    </div>
    <Link to="/product" style={{textDecoration:"none"}} >
    
    <button class="flex mx-auto mt-16  contbtn border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg" >SEE MORE</button>
    </Link>
  </div>
</section>
  
  {/* why us start here */} 
  <div className='container my-5'>
    <div className='row'>
      <div className='col-md-12 text-center'>
<h1 style={{fontFamily:"Time New Roman", fontWeight:"900", marginTop:"20px", fontSize:"58px"}}>Why Us</h1>
<hr className='mx-auto mt-2 ' style={{width:"120px", backgroundColor:"#FFC107", height:"1px"}}/>
      </div>
    </div>

  </div>
 

<section className="text-gray-600 body-font mt-5">
  <div className="container px-5 pb-24 mx-auto flex flex-wrap">
    <div className="flex flex-wrap w-full">
      <div className="lg:w-2/5 md:w-1/2 md:pr-10 md:py-6">
        <div className="flex relative pb-12">
          <div className="h-full w-10 absolute inset-0 flex items-center justify-center">
            <div className="h-full w-1 bg-gray-200 pointer-events-none" />
          </div>
          <div className="flex-shrink-0 w-10 h-10 rounded-full bg-dark inline-flex items-center justify-center text-white relative z-10">
          <i className="fa fa-check text-light me-2" />
           
          </div>
          <div className="flex-grow pl-4">
            <h2 className="font-medium title-font text-sm text-gray-900 mb-1 tracking-wider">Wide Range of Paper Products</h2>
            <p className="leading-relaxed">Paper suppliers offers diverse paper products to cater to a wide range of customer needs.</p>
          </div>
        </div>
        <div className="flex relative pb-12">
          <div className="h-full w-10 absolute inset-0 flex items-center justify-center">
            <div className="h-full w-1 bg-gray-200 pointer-events-none" />
          </div>
          <div className="flex-shrink-0 w-10 h-10 rounded-full bg-dark inline-flex items-center justify-center text-white relative z-10">
          <i className="fa fa-check text-light me-2" />
          </div>
          <div className="flex-grow pl-4">
            <h2 className="font-medium title-font text-sm text-gray-900 mb-1 tracking-wider">User-Friendly Interface</h2>
            <p className="leading-relaxed">Paper suppliers offers a seamless browsing experience and easy navigation for customers.</p>
          </div>
        </div>
        <div className="flex relative pb-12">
          <div className="h-full w-10 absolute inset-0 flex items-center justify-center">
            <div className="h-full w-1 bg-gray-200 pointer-events-none" />
          </div>
          <div className="flex-shrink-0 w-10 h-10 rounded-full bg-dark inline-flex items-center justify-center text-white relative z-10">
          <i className="fa fa-check text-light me-2" />
          </div>
          <div className="flex-grow pl-4">
            <h2 className="font-medium title-font text-sm text-gray-900 mb-1 tracking-wider">Commitment to Excellence</h2>
            <p className="leading-relaxed">High-quality paper products and exceptional customer service ensure customer satisfaction at Paper suppliers.</p>
          </div>
        </div>
        <div className="flex relative pb-12">
          <div className="h-full w-10 absolute inset-0 flex items-center justify-center">
           
          </div>
          <div className="flex-shrink-0 w-10 h-10 rounded-full bg-dark inline-flex items-center justify-center text-white relative z-10">
          <i className="fa fa-check text-light me-2" />
          </div>
          <div className="flex-grow pl-4">
            <h2 className="font-medium title-font text-sm text-gray-900 mb-1 tracking-wider">Simple and Elegant Design</h2>
            <p className="leading-relaxed">Embrace the future of the Paper suppliers with simple and elegant design that marries functionality and aesthetics.</p>
          </div>
        </div>       
      </div>
      <img className="lg:w-3/5 md:w-1/2 object-cover object-center rounded-lg md:mt-0 mt-12 imgbox" src="./assets/why.png" alt="step"  style={{height:"620px"}}/>
    </div>
  </div>
</section>


{/* why us end here */}



<div className='container-fluid mt-5'>
  <div className='row ' style={{backgroundColor:"#FFC107"}}>
    <div className='col-md-7  py-20 text-center '>
              <h2 style={{fontWeight:"bolder"}}>Want to Work Together?</h2>
              <p>
              The Paper suppliers is committed to collaboration, driving innovation, sustainability, and efficiency. By working together, we can create a stronger, more resilient future for the industry and its stakeholders.
              </p>
              <Link to='/contact'>            
              <button className="btn contbtn " style={{border:"1px solid black"}}>CONTACT US</button>
              </Link>
              
    </div>
    <div className='col-md-5 '>
              <img id='block' src='./assets/work.png' style={{ marginTop:"-100px"}} alt='img'/>
    </div>
  </div>
</div>

        </Layout>
   
  )
}

export default Home
