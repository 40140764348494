import React from 'react'
import Layout from '../Components/Layout'
import news from '../Data/Newsapi'
import { Link } from 'react-router-dom'

const News = () => {
  return (
    <Layout>
       <div className='container-fluid'>
        <div className='row'>
<img src='./assets/newsroom.png' alt='carousal'/>

        </div>
      </div>

      {/* news section start here  */}

      <div class="container-fluid d-flex align-items-center justify-content-center position-relative flex-wrap" id="body">
      {news.map((ele, index) => (
      <div key={index} className="card m-5" style={{width: '18rem', border:"none"}}>
  <img src={ele.img} className="card-img-top" alt="news" />
  <div className="card-body">
  <h5 className="card-title">{`${ele.heading.slice(0,50)}`}</h5>
    {/* <h5 className="card-title">{ele.heading}</h5> */}
    <p className="card-text">{`${ele.paragraph.slice(0,100)}...`} </p>
    <Link to={`/${ele.id}`} className="btn contbtn">Read more</Link>
  </div>
</div>
))}
        </div>



      {/* news section end here */}


    </Layout>
  )
}

export default News
