import React from 'react'
import Layout from '../Components/Layout'

const About = () => {
  return (
    <Layout>
        {/* heading caraousl */}
       <div className="container-fluid page-header py-5 mb-5 about" >
       <div className="container-fluid py-5 text-white" id='abouthead'>
       <h1 className='' style={{marginTop:"65px", fontWeight:"bolder"}} >Who we are </h1>
 <p>Paper Suppliers® is the leading mill-brand paper in Lahore Pakistan. Since 2000, we’ve made it easy to choose Paper Made Right®. So you can feel as good about your paper as you do about what’s printed on it — choose Paper Suppliers.</p>
        </div>
        </div>

    


        {/* about us start Here */}
        <div className='container my-5'>
    <div className='row'>
      <div className='col-md-12 text-center'>
<h1 style={{fontFamily:"Time New Roman", fontWeight:"900", marginTop:"20px", fontSize:"58px"}}>About Us</h1>
<hr className='mx-auto mt-2 ' style={{width:"120px", backgroundColor:"#FFC107", height:"1px"}}/>
<p>
<br/>
Welcome to our Paper suppliers website! We are dedicated to providing high-quality paper products that are sustainable and environmentally friendly. Our team is committed to excellence, using the latest technology and innovative processes to deliver products that meet the needs of our customers.
<br/>
 <br/>

With a sleek design in black, yellow, and grey, our website reflects our modern approach to the Paper suppliers. The white background symbolizes our commitment to transparency and purity in everything we do.
<br/>
 <br/>
 

At our core, we believe in the power of paper to inspire creativity, foster communication, and drive progress. Join us on this journey as we strive to make a positive impact on the world through our passion for paper.
</p>
      </div>
    </div>

  </div>
  <div className='container-fluid '>
    <div className='row'>
        <div className='col-md-12'>
<img src='./assets/about3.png' alt='img' />
        </div>
    </div>
</div>
        {/* about us end here */}
   
{/* sustainability section */}
<div className='container-fluid mt-3'>
          <div className="row mx-auto">

            {/* Left Side Column with Text */}

            <div className="col-md-6 mt-5" id="A1">
              <div data-aos="fade-up" className="my-text mt-5 ms-5">
                <h1 id='hed'>Sustainability: more than sheet deep</h1>
                <p className='justify fs-5 text-secondary'>
                At Paper suppliers, we believe that environmental responsibility is much more than a commitment on paper. Our commitment is our paper. It’s why we only produce Paper Made Right®.
                <br/>

                All of our product lines are SFI® or FSC® certified, meaning you can be confident they’re sourced from sustainable tree farms. Paper manufacturing also keeps forests, well, forested — providing an economic incentive to protect land that would otherwise be turned over for development.</p>
              </div>
            </div>


            {/* Right Side Column with Image */}

            <div data-aos="fade-up" className="col-12 col-md-6 text-center mt-5 ">
              <div className="my-text m-auto ms-5">
                <img src="./assets/about1.webp" className="img-fluid" alt="Mission" />
              </div></div>

          </div>
        </div>

        {/* img section start Header */}
          <div className='container-fluid mt-5 '>
               <div className='row'>
                   <div className='col-md-12'>
                    <img src='./assets/about2.webp' alt='about' />
                  </div>
           </div>
          </div>

        {/* img section end here  */}

        {/* paragraph section start here  */}

            <div className='container mb-5' style={{marginTop:"-80px"}} id='para'>
                <div className='row '>
                    <div className='col-md-6' style={{border:"1px solid #ccc",boxShadow: "0px -25px 20px -20px rgba(0, 0, 0, 0.45), 25px 0 20px -20px rgba(0, 0, 0, 0.45), 0px 25px 20px -20px rgba(0, 0, 0, 0.45), -25px 0 20px -20px rgba(0, 0, 0, 0.45)", backgroundColor:"#fff"}}>
                        <h1 style={{ fontWeight:"bold", fontSize:"50px", }}>
                            Why Paper Suppliers?
                        </h1>
                  <p style={{fontSize:"20px"}}>
                    How do we produce such high-performing paper? It takes over a century of experience, high-level quality control, continued innovation, U.S. manufacturing and a dedication to preserve precious, renewable resources for generations to come.
                  </p>
                    </div>
                    <div className='col-md-6'>

                </div>
                </div>
            </div>

        {/* end here */}

        {/* mission section start Here */}
        <div className='container my-5 py-10'>
      <div className='row'>
      <div className='col-md-6'>
          <img className='imgboxx' src='./assets/mission.webp' style={{width:"100%", height:"450px"}} alt='mission' />
        </div>
        <div className='col-md-6'>
          <h3 className='mt-3 fw-900' style={{fontFamily:"Times New Roman",fontSize:"58px"}}>Our Mission</h3>
          <hr className=' mt-1 ' style={{width:"130px", backgroundColor:"#FFC107", height:"1px"}}/>
          <p>

            Our mission at Paper suppliers is to be the go-to destination for high-quality paper products, offering a seamless and enjoyable shopping experience for customers worldwide. We are dedicated to providing a wide range of paper products that meet the needs of our diverse customer base, ensuring that every purchase is met with satisfaction and reliability. With a commitment to excellence in both our products and customer service, we strive to build long-lasting relationships with our customers based on trust and quality. At Paper suppliers, we believe in the power of paper to inspire creativity, foster communication, and drive innovation. Join us on our journey to make a positive impact in the Paper suppliers and beyond.
          </p>
        </div>
       
      </div>
    </div>


        {/* mission section end here */}

        {/* vission section start here */}

        <div className='container my-5 py-10'>
      <div className='row '>
      
        <div className='col-md-6'>
          <h3 className='mt-3 fw-900' style={{fontFamily:"Times New Roman",fontSize:"58px"}}>Our Vision</h3>
          <hr className=' mt-1 ' style={{width:"130px", backgroundColor:"#FFC107", height:"1px"}}/>
          <p>

            Our Vision: At Paper suppliers, our vision is to be the go-to source for high-quality paper products, recognized for our commitment to excellence and customer satisfaction. We strive to continuously innovate and expand our product range to meet the diverse needs of our customers worldwide. By leveraging the latest technology and industry expertise, we aim to provide a seamless and enjoyable shopping experience for our customers. Our dedication to sustainability and environmental responsibility drives us to continuously improve our processes and reduce our carbon footprint. We envision a future where Paper suppliers is synonymous with quality, reliability, and innovation in the Paper suppliers, setting the standard for excellence and customer service.
          </p>
        </div>

        <div className='col-md-6'>
          <img className='imgboxxx' src='./assets/vission.webp' style={{width:"100%", height:"450px"}} alt='vission'/>
        </div>      
      </div>
    </div>

        {/* vission section end here */}

        <div className='container my-5'>
    <div className='row'>
      <div className='col-md-12 text-center'>
<h1 style={{fontFamily:"Time New Roman", fontWeight:"900", marginTop:"20px", fontSize:"58px"}}>What sets us apart?</h1>
<hr className='mx-auto mt-2 ' style={{width:"220px", backgroundColor:"#FFC107", height:"1px"}}/>
      </div>
    </div>

  </div>
  
  <section class="text-gray-600 body-font " style={{backgroundColor:"#FFC107"}}>
  <div class="container px-5 py-24 mx-auto">
    
    <div class="flex flex-wrap -m-4">
    <div class="xl:w-1/3 md:w-1/2 p-4">
        <div class="border border-gray-200 p-6 rounded-lg " style={{backgroundColor:"#fff",boxShadow: "0px 25px 20px -20px rgba(0, 0, 0, 0.45)"}}>
     <h1 style={{ color:"#FFC107", fontSize:"70px"}}> &#9734;</h1>
          <h2 class="text-lg text-gray-900 font-medium title-font mb-2 text-center">High-quality products  </h2>
        
          <p class="leading-relaxed text-base">Paper suppliers offers top-notch paper products that meet the highest industry standards.</p>
          
        </div>
      </div>
      <div class="xl:w-1/3 md:w-1/2 p-4">
        <div class="border border-gray-200 p-6 rounded-lg " style={{backgroundColor:"#fff",boxShadow: "0px 25px 20px -20px rgba(0, 0, 0, 0.45)"}}>
     <h1 style={{ color:"#FFC107", fontSize:"70px"}}> &#9734;</h1>
          <h2 class="text-lg text-gray-900 font-medium title-font mb-2 text-center">User_friendly interface  </h2>
       
          <p class="leading-relaxed text-base">Our website's intuitive design makes it easy for customers to navigate and find the perfect paper products.</p>
          
        </div>
      </div>
      <div class="xl:w-1/3 md:w-1/2 p-4">
        <div class="border border-gray-200 p-6 rounded-lg " style={{backgroundColor:"#fff",boxShadow: "0px 25px 20px -20px rgba(0, 0, 0, 0.45)"}}>
        <h1 style={{ color:"#FFC107", fontSize:"70px"}}> &#9734;</h1>
          <h2 class="text-lg text-gray-900 font-medium title-font mb-2 text-center">Exceptional customer service</h2>
          <p class="leading-relaxed text-base">Paper suppliers goes above and beyond to ensure every customer's paper needs are met with satisfaction.</p>
          
        </div>
      </div>
     
  
    </div>
    
  </div>
</section>

    </Layout>
  )
}

export default About
