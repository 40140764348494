const card = [
{
    heading: "Premium Paper ",
    paragraph: "High-quality paper for important documents and presentations."
},
{
    heading: "Multipurpose Paper",
    paragraph: "Versatile paper suitable for printing, copying, and everyday use."
},
{
    heading: "Everyday Printer Paper",
    paragraph: "Reliable, versatile, and perfect for all your daily printing needs."
},
{
    heading: "Colored Paper",
    paragraph: "Vibrant hues to brighten your projects and unleash your creativity."
},
{
    heading: "Cardstock",
    paragraph: "Thick, sturdy paper for invitations, business cards, and crafts."
},
{
    heading: "Texture Paper",
    paragraph: "Texture paper with a unique surface texture for artistic projects and special invitations."
},
{
    heading: "Paper",
    paragraph: "Paper use in different field such as broucher, books, magazine, medicine, pufflet, etc."
},
{
    heading: "Card/Board",
    paragraph: "Card/Board use in different field such as  medicine, boxes, gifts, fastfood, packing, calender etc. "
},
{
    heading: "Offset Paper",
    paragraph: "Offest paper use in different field such as broucher, books, magazine, medicine, pufflet, etc."
},
{
    heading: "Stickers",
    paragraph: "Stickers which is use in different products such as cosmetics, bootles, glass, juices, mobiles, all food items, all electronic items, all grocery items, etc. "
},
{
    heading: "Every/Albaster Card",
    paragraph: "Every/Albaster card use in different field such as  medicine, boxes, gifts, fastfood, packing, calender etc. "
},
{
    heading: "Melamine",
    paragraph: "Melamine paper which is used for grocery printed such plates, mugs, bowls, etc."
},
{
    heading: "Butter Paper",
    paragraph: "Butter paper can use for fast food bakkery items, sweets such shawarma, burger, pizza, cookies, etc."
},
{
    heading: "Carbonless",
    paragraph: "Carbonless can use for bill books, school books, art, etc."
},
{
    heading: "Cromo Card",
    paragraph: "Cromo card use in different field such as  medicine, boxes, gifts, fastfood, packing, calender etc."
},
{
    heading: "A4 Box",
    paragraph: "Company deal with A4 size paper. It is used for printer."
},
{
    heading: "Bleach Card",
    paragraph: " Bleach card use in different field such as  medicine, boxes, cookies box, pizza box, gifts, fastfood, packing, calender etc."
},
{
    heading: "Film-Fancy Sticker",
    paragraph: "Fancy stickers which is use in different products such as cosmetics, bootles, glass, juices, mobiles, all food items, all electronic items, all grocery items, etc"
},


]

export default card;