// import logo from './logo.svg';
import './App.css';
import {BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Pages/Home';
import About from './Pages/About';
import Contact from './Pages/Contact';
import Gallery from './Pages/Gallery';
import Product from './Pages/Product';
import News from './Pages/News';
import Newsdetail from './Pages/Newsdetail';

function App() {
  return (
    <Router>
    <Routes>
      <Route path='/' element={<Home/>}/>
      <Route path='/about' element={<About/>}/>
      <Route path='/contact' element={<Contact/>}/>
      <Route path='/gallery' element={<Gallery/>}/>
      <Route path='/product' element={<Product/>}/>
      <Route path='/news' element={<News/>}/>
      <Route path='/:nId' element={<Newsdetail/>}/>
    </Routes>
    
    </Router>
  );
}

export default App;
