import React from 'react'
import Layout from '../Components/Layout'
import card from '../Data/Card'
import { Link } from 'react-router-dom'

const Product = () => {
  return (
    <Layout>
      <div className='container-fluid'>
        <div className='row'>
<img src='./assets/img.png' alt='img'/>
        </div>
      </div>

<section class="text-gray-600 body-font texture">
  <div class="container px-5 py-24 mx-auto">
    
    <div class="flex flex-wrap -m-4" >
      {card.map((ele, index) => (
      <div key={index} class="xl:w-1/3 md:w-1/2 p-4 d-flex">
        <div   class=" p-6 rounded-lg " id='product' style={{backgroundColor:"#fff",boxShadow: "0px 25px 20px -20px rgba(0, 0, 0, 0.45)"}}>
        
          <h2 class="text-lg text-gray-900 font-medium title-font mb-2 text-center">{ele.heading}</h2>
          <hr className='mx-auto ' style={{width:"50px", backgroundColor:"#FFC107", height:"1px"}}/>
          <p class="leading-relaxed text-base">{ele.paragraph}</p>
          
        </div>
      </div>
        ))}
      </div>
      </div>
      </section>
     
      <div className='container my-3' style={{backgroundColor:"#FFC107"}}>
<div className='row p-5'>
    <div className='col-md-10'>
        <div className='heading'>
<h2 className='text-white'>LOOKING FOR QUALITY PAPER SUPPLIERS</h2>
<p className='fs-5'>We provide Different High Quality Paper Services. GET A QUOTE IN 20% OFF</p>
        </div>

    </div>
    <div className='col-md-2'>
    <div className='serbtn text-center p-2 '>
        <Link style={{textDecoration:"none" , color:"#fff"}} to="/contact">
            GET A QUOTE
          </Link>
        </div>

    </div>
</div>
</div>
    </Layout>
  )
}

export default Product
