import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <div>
       <footer class=" body-font " style={{backgroundColor:"#000"}}>
  <div class="container px-5 py-24 mx-auto flex md:items-center lg:items-start md:flex-row md:flex-nowrap flex-wrap flex-col ">
    <div class="w-64 flex-shrink-0 md:mx-0 mx-auto text-center md:text-left">
      <Link to='/' class="flex title-font font-medium items-center md:justify-start justify-center text-gray-900">
        <span class="ml-3 text-xl text-white bg-white "><img src='./assets/logo1.png' alt='footer'/></span>
      </Link>
      <p class="mt-2 text-sm text-gray-500">The papersuppliers innovates with sustainable practices and advanced technologies to meet evolving global demands.</p>
    </div>
    <div class="flex-grow flex flex-wrap md:pl-20 -mb-10 md:mt-0 mt-10 md:text-left ">
    <div class="lg:w-1/4 md:w-1/2 w-full px-4">
        <h2 class="title-font font-medium  tracking-widest text-sm text-white mb-3">Useful Links</h2>
        <hr className=' ' style={{width:"50px", backgroundColor:"#FFC107", height:"1px"}}/>
        <nav class="list-none mb-10 text-secondary" style={{fontSize:"15px"}}>
          <li>
            <Link  to='/about' className="text-gray-600 hover:text-gray-800 ">About</Link>
          </li>
          <li>
            <Link to='/product' class="text-gray-600 hover:text-gray-800">Services</Link>
          </li>
          <li>
            <Link to='/gallery' class="text-gray-600 hover:text-gray-800">Projects</Link>
          </li>
          <li>
            <Link to='/contact' class="text-gray-600 hover:text-gray-800">Offer</Link>
          </li>
        </nav>
      </div>
      <div class="lg:w-1/4 md:w-1/2 w-full px-4 ">
        <h2 class="title-font font-medium  tracking-widest text-sm text-white mb-3">Quick Links</h2>
        <hr className=' ' style={{width:"50px", backgroundColor:"#FFC107", height:"1px"}}/>
        <nav class="list-none mb-10 text-secondary" style={{fontSize:"15px"}}>
          <li>
            <Link to='/' className="text-gray-600 hover:text-gray-800 ">Home</Link>
          </li>
          <li>
            <Link to='/product' class="text-gray-600 hover:text-gray-800">Product</Link>
          </li>
          <li>
            <Link to='/contact' class="text-gray-600 hover:text-gray-800">Contact Us</Link>
          </li>
          <li>
            <Link to='/news' class="text-gray-600 hover:text-gray-800">Newsroom</Link>
          </li>
        </nav>
      </div>
      <div class="lg:w-1/2 md:w-1/2 w-full" style={{paddingLeft:"0px"}}>
        <h2 class="title-font font-medium  tracking-widest text-sm text-white mb-3">Contact</h2>
        <hr className=' ' style={{width:"50px", backgroundColor:"#FFC107", height:"1px"}}/>
        <nav class=" list-none mb-10 text-secondary" style={{fontSize:"15px"}}>
          
            <p className="text-gray-600 hover:text-gray-800  "> <i className="fa fa-envelope " style={{color:"#FFC107"}} aria-hidden="true"  /> &nbsp;
            info@paper-supply.com</p>
         
          <li className='my-2'>
            <p class="text-gray-600 hover:text-gray-800"><i className="fa fa-phone" aria-hidden="true" style={{color:"#FFC107"}} /> &nbsp; +92 310-7770424</p>
          </li>
          <li>
            <p class="text-gray-600 hover:text-gray-800"> <i className="fa fa-map-marker" aria-hidden="true" style={{color:"#FFC107"}}/>  &nbsp;&nbsp;  Gourmet Sanda Road</p>
          </li>
         
        </nav>
      </div>
      
      
    </div>
  </div>
  <div class="bg-gray-100">
    <div class="container mx-auto py-4 px-5 flex flex-wrap flex-col sm:flex-row">
      <p class="text-gray-500 text-sm text-center sm:text-left">© 2024 Copyright —
       @papersuppliers
      </p>
      <span class="inline-flex sm:ml-auto sm:mt-0 mt-2 justify-center sm:justify-start">
        <Link to='https://www.facebook.com/paperssupplier' class="text-gray-500 social">
      <i class="fa-brands fa-facebook-f"></i>
        </Link>
        <Link to='https://www.instagram.com/papersupplier2024/' class="ml-3 text-gray-500 social">
        <i class="fa-brands fa-instagram"></i>
        </Link>
        <Link to='https://www.linkedin.com/company/paper-supplier/' class="ml-3 text-gray-500 social">
        <i class="fa-brands fa-linkedin-in"></i>
         
        </Link>
        <Link to='https://www.youtube.com/@paper-supplier' class="ml-3 text-gray-500 social">
        
        <i class="fa-brands fa-youtube"></i>
        </Link>
      </span>
    </div>
  </div>
</footer>
    </div>
  )
}

export default Footer
