import React from 'react'
import Layout from '../Components/Layout'
import { Link, useParams } from 'react-router-dom'
import news from '../Data/Newsapi'

const Newsdetail = () => {
    const {nId}  = useParams()
    let filterData = news.find(x => x.id === nId)
  return (
    <Layout>
      {/* Page Header Start */}
      <div id="myCarousel" className="carousel slide" data-ride="carousel">
 
 {/* Wrapper for slides */}
 <div className="carousel-inner" role="listbox">
   <div className="item active">
     <img src={filterData.img} alt="newspage" style={{width:"100%", height:"300px"}} />
     <div className="carousel-caption  d-md-block">
     
<nav  aria-label="breadcrumb" style={{ display: 'flex', justifyContent: 'start' }} >
 <ol className='d-flex ' >
   {/* <li className="breadcrumb-item"><Link to="/" style={{color:"#fff", fontSize:"20px"}}>Home</Link></li> */}
   <li className="breadcrumb-item active " aria-current="page" style={{color:"#fff", fontSize:"20px"}}>Newsroom</li>
   <li className="breadcrumb-item active" aria-current="page" style={{color:"#fff", fontSize:"20px"}}>{filterData.heading} </li>
 </ol>
</nav>

 
 <div style={{ display: 'flex', justifyContent: 'start' }}>

   <h3 className="display-3 text-light  animated slideInDown ">{filterData.heading} </h3>
  
 </div>
     {/* <div className="container h-100">
 
</div> */}
     </div>
   </div>
   </div>
   </div>

{/* Page Header End */}

<div className='container'>
    <div className='container mt-5'>
        <h1>{filterData.paragraph}</h1>
        <p className='my-5' style={{fontSize:"30px"}}>
        {filterData.para1}
        </p>
        <p style={{fontSize:"20px"}}>
        {filterData.para2}
        </p>
    </div>
</div>

<div className='container my-5'>
    <div className='row'>
        <div className='col-md-5'>
            <img src={filterData.imgsrc} style={{width:"400px", height:"300px"}} alt='paper'/>
        </div>
        <div className='col-md-7' >
            <p style={{fontSize:"20px"}}>{filterData.para3}</p>
        </div>
    </div>
</div>
<div className='container'>
    <div className='row'>
        <div className='col-md-12'>

        <p className='my-5' style={{fontSize:"20px"}}>
    {filterData.para4}
        </p>
        </div>
    </div>
</div>

    </Layout>
  )
}

export default Newsdetail
